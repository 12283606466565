<template>
    <div class="page-layout-wrapper">
        <div class="container">
            <PageHeader
                v-if="$route.path == '/songs'"
                title="Songs"
            >
                <router-link
                    to="/songs/upload"
                    class="btn"
                >
                    Upload Song
                </router-link>
            </PageHeader>
        </div>

        <router-view />
    </div>
</template>

<script>
import PageHeader from '@/components/Page/PageHeader'

export default {
  name: 'Songs',
  components: {
    PageHeader
  }
}
</script>
